import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import rootReducer from "../reducers/rootReducer";
import { sessionService } from "redux-react-session";

const validateSession = (session) => {
  // check if your session is still valid
  return true;
};

const options = {
  redirectPath: "/",
  driver: "COOKIES",
  validateSession,
};

const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware(), thunk],
});

sessionService.initSessionService(store, options);

export default store;
