import { sessionService } from "redux-react-session";
import moment from "moment";
import axios from "axios";

// the remote endpoint and local
const tenanturl = process.env.REACT_APP_BASEURL + "/api/tenant";
const contractUrl = process.env.REACT_APP_BASEURL + "/api/contract";
let currentDate = new Date();

export const loginUser = (values, navigate) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${tenanturl}/signin`,
        {
          email: values.email,
          password: values.password,
          acceptTOS: values.acceptedTos,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      const result = response.data;

      if (result.status === "FAILED") {
        console.log("Login failed:", result.message);
      } else if (result.status === "SUCCESS") {
        const token = result.data[0].tenant_id;

        localStorage.setItem("acceptedTos", true);
        localStorage.setItem("email", result.data[0].email);
        localStorage.setItem("role", result.data[0].role);
        localStorage.setItem("name", result.data[0].foodcourt_name);
        localStorage.setItem("tenantid", token);

        await sessionService.saveSession(token);
        await sessionService.saveUser(result.data[0]);

        console.log("User session and data saved successfully");

        const url = contractUrl + "/retrieve/" + token;

        const contractResponse = await axios.get(url, {
          headers: { "Content-Type": "application/json" },
        });

        const contractResult = contractResponse.data;

        if (contractResult.status === "SUCCESS") {
          let a = moment(contractResult.data.start_Date).add(
            contractResult.data.contract_Period,
            "years"
          );
          let b = moment(currentDate);

          if (moment(a).isSameOrBefore(b)) {
            console.log("Contract expired");
            navigate("/404");
          } else {
            console.log("Login successful");
            navigate("/dashboard");
            window.location.reload();
          }
        } else {
          console.log("Error getting contract data:", contractResult.message);
          navigate("/505");
        }
      }
    } catch (err) {
      console.error("Error during login:", err);
    }
  };
};

export const signupUser = (
  credentials,
  history,
  setFieldError,
  setSubmitting
) => {
  return (dispatch) => {
    fetch(`${tenanturl}/signup`, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        name: credentials.name,
        email: credentials.email,
        password: credentials.password,
      }),
      headers: { "content-type": "application/JSON" },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "FAILED") {
          const { message } = result;

          //check for specific error
          if (message.includes("name")) {
            setFieldError("name", message);
          } else if (message.includes("email")) {
            setFieldError("email", message);
          } else if (message.includes("password")) {
            setFieldError("password", message);
          } else if (message.includes("confirmPassword")) {
            setFieldError("confirmPassword", message);
          }
        } else if (result.status === "SUCCESS") {
          //display message for email verification
          // history.push(`/emailsent/${credentials.email}`);
          history.push(`/login`);
        }
        //complete submission
        setSubmitting(false);
      })
      .catch((err) => console.error(err));
  };
};

export const logoutUser = (navigate) => {
  return async (dispatch) => {
    try {
      await sessionService.deleteSession();
      await sessionService.deleteUser();

      localStorage.removeItem("name");
      localStorage.removeItem("email");
      localStorage.removeItem("role");
      localStorage.removeItem("acceptedTos");

      navigate("/login");
      window.location.reload();
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };
};

export const forgetpassword = (
  credentials,
  history,
  setFieldError,
  setSubmitting
) => {
  //make checks and get some data

  return () => {
    fetch(`${tenanturl}/passwordresetrequest`, {
      method: "POST",
      body: JSON.stringify({
        email: credentials.email,
        redirectUrl: credentials.redirectUrl,
      }),
      headers: { "content-type": "application/JSON" },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "FAILED") {
          const { message } = result;

          //check for specific error
          if (message.includes("email")) {
            setFieldError("email", message);
          }
        } else if (result.status === "PENDING") {
          const { email } = credentials;
          history.push(`/emailsent/${email}/${true}`);
        }

        //complete submission
        setSubmitting(false);
      })
      .catch((err) => console.error(err));
  };
};

export const resetPassword = (
  credentials,
  history,
  setFieldError,
  setSubmitting
) => {
  //make checks and get some data
  return () => {
    fetch(`${tenanturl}/passwordreset`, {
      method: "POST",
      body: JSON.stringify({
        userID: credentials.userID,
        resetString: credentials.resetString,
        newPassword: credentials.newPassword,
      }),
      headers: { "content-type": "application/JSON" },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "FAILED") {
          const { message } = result;

          //check for specific error
          if (message.includes("password")) {
            setFieldError("newPassword", message);
          }
        } else if (result.status === "SUCCESS") {
          history.push(`/emailsent`);
        }

        //complete submission
        setSubmitting(false);
      })
      .catch((err) => console.error(err));
  };
};
