import duotone from "icons/duotone";
export const navigations = [
  {
    type: "label",
    label: "Dashboard",
  },
  {
    name: "Home",
    path: "/dashboard",
    icon: duotone.Ecommerce,
  },
  {
    type: "label",
    label: "Order Management",
  },
  {
    name: "Payment",
    path: "/dashboard/payment",
    icon: duotone.BadgeDollar,
  },
  {
    name: "Order",
    path: "/dashboard/order-management",
    icon: duotone.DataTable,
  },

  // {
  //   name: "Tax Invoice",
  //   path: "/dashboard/tax-invoice",
  //   icon: duotone.Invoice,
  // },
  {
    name: "Customer List",
    path: "/dashboard/customer-management",
    icon: duotone.UserList,
  },
  {
    type: "label",
    label: "Inventory Management",
  },
  {
    name: "Product",
    path: "/dashboard/product",
    icon: duotone.LayerGroup,
  },
  // {
  //   name: "Purchase Order",
  //   path: "/dashboard/purchase-order",
  //   icon: duotone.DataTable,
  // },
  // {
  //   name: "Warehouse & Stock",
  //   path: "/dashboard/warehouse",
  //   icon: duotone.Warehouse,
  // },
  // {
  //   type: "label",
  //   label: "Sales Management",
  // },
  // {
  //   type: "label",
  //   label: "Finance Management",
  // },
  {
    type: "label",
    label: "Settings",
  },
  {
    name: "Account Setting",
    path: "/dashboard/settings",
    icon: duotone.Settings,
  },
];
