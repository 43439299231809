import { lazy, Suspense, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import useSettings from "hooks/useSettings";
import LoadingScreen from "components/LoadingScreen";
import LayoutV3 from "layouts/layout-v3/DashboardLayout";
import { sessionService } from "redux-react-session";

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
}; // dashboards

const SaaS = Loadable(lazy(() => import("./pages/dashboards/saas"))); //auth page

const Login = Loadable(lazy(() => import("./pages/authentication/login")));
const Register = Loadable(
  lazy(() => import("./pages/authentication/register"))
);
const NewPassword = Loadable(
  lazy(() => import("./pages/authentication/new-password"))
);
const ForgetPassword = Loadable(
  lazy(() => import("./pages/authentication/forget-password"))
);
const PrivacyTermsPage = Loadable(
  lazy(() => import("./pages/authentication/privacyterms"))
); // admin page

const PaymentPage = Loadable(lazy(() => import("./pages/payment/paymentPage")));
const CreatePayment = Loadable(
  lazy(() => import("./pages/payment/createPayment"))
);
const ProductList = Loadable(
  lazy(() => import("./pages/product/product-list"))
);
const CustomerManagement = Loadable(
  lazy(() => import("./pages/admin-ecommerce/customer-management"))
);
const OrderManagement = Loadable(
  lazy(() => import("./pages/admin-ecommerce/order-management"))
);
const CreateProduct = Loadable(
  lazy(() => import("./pages/product/create-product"))
);
const EditProduct = Loadable(
  lazy(() => import("./pages/product/edit-product"))
);
const ProductDetails = Loadable(
  lazy(() => import("./pages/product/product-details"))
);
const ManageCategory = Loadable(
  lazy(() => import("./pages/product/manage-category"))
);
const TaxInvoice = Loadable(lazy(() => import("./pages/invoice/tax-invoice")));
const CreateInvoice = Loadable(lazy(() => import("./pages/invoice/create")));
const DetailInvoice = Loadable(lazy(() => import("./pages/invoice/details")));
const WarehouseStock = Loadable(
  lazy(() => import("./pages/user-list/user-list-view-v2"))
);
const PurchaseOrder = Loadable(
  lazy(() => import("./pages/inventory/purchase-order"))
);

const AccountSettings = Loadable(
  lazy(() => import("./pages/accounts/account-v2"))
); // 404/Error page

const Error = Loadable(lazy(() => import("./pages/404")));

const ActiveLayout = () => {
  const { settings } = useSettings();

  switch (settings.activeLayout) {
    case "layout3":
      return <LayoutV3 />;

    default:
      return <LayoutV3 />;
  }
};

const PrivateRoute = ({ element }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const session = await sessionService.loadSession();
        const isAuthenticated = session && session.isAuthenticated;
      } catch (error) {
        navigate("/login");
      }
    };

    checkAuthentication();
  }, []);
  return element;
};

const BasicRoute = ({ element }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const session = await sessionService.loadSession();
        const isAuthenticated = session && session.isAuthenticated;

        navigate("/dashboard");
      } catch (error) {
        return element;
      }
    };

    checkAuthentication();
  }, [navigate]);
  return element;
};

const routes = () => {
  return [
    { path: "/", element: <Navigate to="/dashboard" /> },
    { path: "login", element: <BasicRoute element={<Login />} /> },
    { path: "register", element: <BasicRoute element={<Register />} /> },
    { path: "new-password", element: <BasicRoute element={<NewPassword />} /> },
    {
      path: "forget-password",
      element: <BasicRoute element={<ForgetPassword />} />,
    },
    { path: "privacyterms", element: <PrivacyTermsPage /> },
    {
      path: "dashboard",
      element: <PrivateRoute element={<ActiveLayout />} />,
      children: [
        ...dashboardRoutes,
        ...productRoutes.map((route) => ({
          ...route,
          element: <PrivateRoute element={route.element} />,
        })),
      ],
    },
    { path: "*", element: <Error /> },
  ];
};

const productRoutes = [
  { path: "", element: <ProductList /> },
  { path: "manage-category", element: <ManageCategory /> },
  { path: "create-product", element: <CreateProduct /> },
  { path: "edit-product/:menuId", element: <EditProduct /> },
  { path: "product-details", element: <ProductDetails /> },
];

const dashboardRoutes = [
  { path: "", element: <SaaS /> },
  { path: "product", children: productRoutes },
  { path: "customer-management", element: <CustomerManagement /> },
  { path: "order-management", element: <OrderManagement /> },
  { path: "tax-invoice", element: <TaxInvoice /> },
  { path: "create-invoice", element: <CreateInvoice /> },
  { path: "invoice-details", element: <DetailInvoice /> },
  { path: "purchase-order", element: <PurchaseOrder /> },
  { path: "warehouse", element: <WarehouseStock /> },
  { path: "payment", element: <PaymentPage /> },
  { path: "create-payment", element: <CreatePayment /> },
  { path: "settings", element: <AccountSettings /> },
];

export default routes;
