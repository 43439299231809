import { AppBar, Box, styled, Toolbar, useMediaQuery } from "@mui/material";
import ProfilePopover from "./popovers/ProfilePopover";

// ------------------------------------------------
// custom styled components
const DashboardHeaderRoot = styled(AppBar)(({ theme }) => ({
  zIndex: 11,
  boxShadow: "none",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  backdropFilter: "blur(6px)",
  backgroundColor: "transparent",
  color: theme.palette.text.primary,
}));
const StyledToolBar = styled(Toolbar)(() => ({
  "@media (min-width: 0px)": {
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: "auto",
  },
}));

const ToggleIcon = styled(Box)(({ theme, width }) => ({
  height: 3,
  margin: "5px",
  marginLeft: 0,
  width: width || 25,
  borderRadius: "10px",
  transition: "width 0.3s",
  backgroundColor: theme.palette.primary.main,
}));

const DashboardHeader = (props) => {
  const { setShowMobileSideBar } = props;
  const downMd = useMediaQuery((theme) => theme.breakpoints.down(1200));

  return (
    <DashboardHeaderRoot position="sticky">
      <StyledToolBar>
        {downMd && (
          <Box
            sx={{
              cursor: "pointer",
            }}
            onClick={setShowMobileSideBar}
          >
            <ToggleIcon />
            <ToggleIcon width={18} />
            <ToggleIcon width={9} />
          </Box>
        )}

        <Box flexGrow={1} ml={1} />

        <ProfilePopover />
      </StyledToolBar>
    </DashboardHeaderRoot>
  );
};

export default DashboardHeader;
